import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FaPhone, FaBookOpen, FaCode, FaLanguage } from 'react-icons/fa';
import logo from '../../assets/logo.svg';
import './CustomNavbar.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';


function CustomNavbar() {
  const [expanded, setExpanded] = useState(false);
  const { i18n, t } = useTranslation();
  const [checked, setChecked] = useState(i18n.language === 'es');

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    const newLang = nextChecked ? 'es' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <div>
      <Navbar className="white-bg margin-left" variant="light" expand="lg" expanded={expanded}>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} className="d-inline-block align-top custom-nav-logo" alt="Logo" />
        </Navbar.Brand>
        <div className="custom-nav-menu">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <div className="left-align-items">
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="c-nav-menu">
                <Nav.Link href="contact-us">
                  <FaPhone /> {t('Contact')}
                </Nav.Link>
                <Nav.Link href="#">
                  <div className='d-flex justify-content-center align-items-center'>
                    <FaLanguage /><span className={`language-label ${!checked ? 'active' : ''}`}>En </span>
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      onColor="#4CAF50"
                      offColor="#FF5722"
                      onHandleColor="#fff"
                      offHandleColor="#fff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={22}
                      width={48}
                      className="language-switch ms-1 me-1"
                    />
                    <span className={`language-label ${checked ? 'active' : ''}`}> Es</span>
                  </div>
                </Nav.Link>
                <Nav.Link href="https://shop.hansa-flex.cl/es_ES/" target="_blank" rel="noopener noreferrer">
                  <FaBookOpen /> {t('Online Catalog')}
                </Nav.Link>
                <Nav.Link href="https://my.hansa-flex.cl/en_GB/login" target="_blank" rel="noopener noreferrer">
                  <FaCode /> {t('X-CODE Manager')}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
